#page1 {
    min-width: 100%;
    min-height: 100vh;
    background-color: #ff8b03;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#page1 > p {
    font-weight: 700;
    font-size: 64px;
    letter-spacing: -0.5px;
    color: white;
    line-height: 100px;
    text-align: center;
}

#page1 > img {
    width: 396px;
    height: 396px;
    margin-top: 15px;
    margin-bottom: 8px;
}

@media screen and (max-width: 1280px) {
    #page1 > p {
        font-weight: 700;
        font-size: 4.5vw;
        letter-spacing: -0.03vw;
        color: white;
        line-height: 6.9vw;
        text-align: center;
    }
    
    #page1 > img {
        width: 27.5vw;
        height: 27.5vw;
        margin-top: 1vw;
        margin-bottom: 0.5vw;
    }
}

@media screen and (max-width: 640px) {
    #page1 > p {
        font-size: calc(4.5vw * 2);
        letter-spacing: calc(-0.03vw * 2);
        line-height: calc(6.9vw * 2);
    }

    #page1 > img {
        width: calc(27.5vw * 2.5);
        height: calc(27.5vw * 2.5);
        margin-top: calc(1vw * 2.5);
        margin-bottom: calc(0.5vw * 2.5);
    }
}
