#privacy {
    padding: 10px;
    max-width: 1040px;
    margin: auto;
}

#privacy > h1 {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 34px;
}

#privacy p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
}

#privacy p.num {
    font-weight: 700;
}

#privacy p.small {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
}

#privacy li {
    margin-left: 32px;
}