@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

* {
  padding: 0;
  margin : 0;
}

p {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

.section {
  position: relative;
  overflow: hidden !important;
}

.pc {
  display: block;
}

.mobile {
  display: none;
}

@media screen and (max-width: 640px) {
  .mobile {
    display: block;
  }
  .pc {
    display: none;
  }
  body {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
}