#service {
    padding: 10px;
    max-width: 1040px;
    margin: auto;
}

#service > h1 {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 34px;
}

#service p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
}

#service p.num {
    font-weight: 700;
}

#service p.small {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
}

#service li {
    margin-left: 32px;
}