#page2 {
    min-width: 100%;
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#page2 > .ani-wrap {
    position: relative;
    height: 352px;
    width: 100%;
    margin-bottom: 108px;
}

#page2 > .ani-wrap > .back {
    position: absolute;
    width: 352px;
    height: 352px;
    left: 50%;
    transform: translateX(-50%);
}

#page2 .ani-wrap > .back > .wrap {
    width: calc(352px * 24);
    position: absolute;
    animation: ani1 10s linear infinite;
}

#page2 .ani-wrap > .back > .wrap > .item {
    width: 352px;
    height: 352px;
    text-align: center;
    align-content: center;
    float: left;
}

#page2 .ani-wrap .back > .wrap > .item > img {
    width: 244px;
    height: 244px;
}

#page2 .ani-wrap > .main {
    position: absolute;
    width: 352px;
    height: 352px;
    border-radius: 50%;
    background-color: #ff8b03;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
}

#page2 .ani-wrap .main > .wrap {
    position: absolute;
    width: calc(352px * 16);
    animation: ani2 10s linear infinite;
}

#page2 .ani-wrap .main > .wrap > .item {
    width: 352px;
    height: 352px;
    text-align: center;
    align-content: center;
    float: left;
}

#page2 .ani-wrap .main > .wrap > .item > img {
    width: 244px;
    height: 244px;
}

#page2 p {
    font-weight: 700;
    font-size: 64px;
    letter-spacing: -0.5px;
    color: black;
    line-height: 100px;
    text-align: center;
}

@media screen and (max-width: 640px) {
    #page2 p {
        font-size: calc(4.5vw * 2);
        letter-spacing: calc(-0.03vw * 2);
        line-height: calc(6.9vw * 2);
    }

    #page2 .ani-wrap {
        height: calc(24.5vw * 2.5);
        margin-bottom: calc(7.5vw * 2.5);
    }

    #page2 .ani-wrap > .back {
        width: calc(24.5vw * 2.5);
        height: calc(24.5vw * 2.5);
    }

    #page2 .ani-wrap .back > .wrap {
        width: calc(24.5vw * 2.5 * 24);
        animation: ani11 10s linear infinite;
    }

    #page2 .ani-wrap .back > .wrap > .item {
        width: calc(24.5vw * 2.5);
        height: calc(24.5vw * 2.5);
    }

    #page2 .ani-wrap .back > .wrap > .item > img {
        width: calc(16.9vw * 2.5);
        height: calc(16.9vw * 2.5);
    }

    #page2 .ani-wrap > .main {
        width: calc(24.5vw * 2.5);
        height: calc(24.5vw * 2.5);
        border-radius: calc(13.8vw * 2.5);
    }

    #page2 .ani-wrap .main > .wrap {
        width: calc(24.5vw * 2.5 * 16);
        animation: ani12 10s linear infinite;
    }

    #page2 .ani-wrap .main > .wrap > .item {
        width: calc(24.5vw * 2.5);
        height: calc(24.5vw * 2.5);
    }

    #page2 .ani-wrap .main > .wrap > .item > img {
        width: calc(16.9vw * 2.5);
        height: calc(16.9vw * 2.5);
    }
}

@keyframes ani1 {
    0% {
        left: calc(352px * -8);
    }
    10.5% {
        left: calc(352px * -9);
    }
    12.5% {
        left: calc(352px * -9);
    }
    23% {
        left: calc(352px * -10);
    }
    25% {
        left: calc(352px * -10);
    }
    35.5% {
        left: calc(352px * -11);
    }
    37.5% {
        left: calc(352px * -11);
    }
    48% {
        left: calc(352px * -12);
    }
    50% {
        left: calc(352px * -12);
    }
    60.5% {
        left: calc(352px * -13);
    }
    62.5% {
        left: calc(352px * -13);
    }
    73% {
        left: calc(352px * -14);
    }
    75% {
        left: calc(352px * -14);
    }
    85.5% {
        left: calc(352px * -15);
    }
    87.5% {
        left: calc(352px * -15);
    }
    98% {
        left: calc(352px * -16);
    }
    100% {
        left: calc(352px * -16);
    }
}

@keyframes ani2 {
    0% {
        left: 0;
    }
    10.5% {
        left: calc(352px * -1);
    }
    12.5% {
        left: calc(352px * -1);
    }
    23% {
        left: calc(352px * -2);
    }
    25% {
        left: calc(352px * -2);
    }
    35.5% {
        left: calc(352px * -3);
    }
    37.5% {
        left: calc(352px * -3);
    }
    48% {
        left: calc(352px * -4);
    }
    50% {
        left: calc(352px * -4);
    }
    60.5% {
        left: calc(352px * -5);
    }
    62.5% {
        left: calc(352px * -5);
    }
    73% {
        left: calc(352px * -6);
    }
    75% {
        left: calc(352px * -6);
    }
    85.5% {
        left: calc(352px * -7);
    }
    87.5% {
        left: calc(352px * -7);
    }
    98% {
        left: calc(352px * -8);
    }
    100% {
        left: calc(352px * -8);
    }
}

@keyframes ani11 {
    0% {
        left: calc(24.5vw * 2.5 * -8);
    }
    10.5% {
        left: calc(24.5vw * 2.5 * -9);
    }
    12.5% {
        left: calc(24.5vw * 2.5 * -9);
    }
    23% {
        left: calc(24.5vw * 2.5 * -10);
    }
    25% {
        left: calc(24.5vw * 2.5 * -10);
    }
    35.5% {
        left: calc(24.5vw * 2.5 * -11);
    }
    37.5% {
        left: calc(24.5vw * 2.5 * -11);
    }
    48% {
        left: calc(24.5vw * 2.5 * -12);
    }
    50% {
        left: calc(24.5vw * 2.5 * -12);
    }
    60.5% {
        left: calc(24.5vw * 2.5 * -13);
    }
    62.5% {
        left: calc(24.5vw * 2.5 * -13);
    }
    73% {
        left: calc(24.5vw * 2.5 * -14);
    }
    75% {
        left: calc(24.5vw * 2.5 * -14);
    }
    85.5% {
        left: calc(24.5vw * 2.5 * -15);
    }
    87.5% {
        left: calc(24.5vw * 2.5 * -15);
    }
    98% {
        left: calc(24.5vw * 2.5 * -16);
    }
    100% {
        left: calc(24.5vw * 2.5 * -16);
    }
}

@keyframes ani12 {
    0% {
        left: 0;
    }
    10.5% {
        left: calc(24.5vw * 2.5 * -1);
    }
    12.5% {
        left: calc(24.5vw * 2.5 * -1);
    }
    23% {
        left: calc(24.5vw * 2.5 * -2);
    }
    25% {
        left: calc(24.5vw * 2.5 * -2);
    }
    35.5% {
        left: calc(24.5vw * 2.5 * -3);
    }
    37.5% {
        left: calc(24.5vw * 2.5 * -3);
    }
    48% {
        left: calc(24.5vw * 2.5 * -4);
    }
    50% {
        left: calc(24.5vw * 2.5 * -4);
    }
    60.5% {
        left: calc(24.5vw * 2.5 * -5);
    }
    62.5% {
        left: calc(24.5vw * 2.5 * -5);
    }
    73% {
        left: calc(24.5vw * 2.5 * -6);
    }
    75% {
        left: calc(24.5vw * 2.5 * -6);
    }
    85.5% {
        left: calc(24.5vw * 2.5 * -7);
    }
    87.5% {
        left: calc(24.5vw * 2.5 * -7);
    }
    98% {
        left: calc(24.5vw * 2.5 * -8);
    }
    100% {
        left: calc(24.5vw * 2.5 * -8);
    }
}