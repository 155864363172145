#page5 {
    min-width: 100%;
    min-height: 100vh;
    background-color: #ff8b03;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#page5 p {
    font-weight: 700;
    font-size: 64px;
    letter-spacing: -0.5px;
    color: rgb(255, 222, 117);
    line-height: 120px;
    text-align: center;
}

#page5 p.under {
    text-decoration: underline;
}

.body {
    flex: 1;
    align-content: center;
}

#page5 > .body > .btn-wrap > a {
    background: none;
    border: none;
    width: 350px;
    margin-top: 62px;
    display: inline-block;
}

#page5 > .body > .btn-wrap > a + a {
    margin-left: 2.2vw;
}

#page5 > .body > .btn-wrap > a > img {
    width: 100%;
}

#page5 > .bottom {
    width: 100%;
    align-items: center;
    background-color: rgb(255, 115, 0);
}

#page5 > .bottom > .info-wrap {
    max-width: 1040px;
    display: flex;
    flex-direction: row;
    margin: auto;
    padding-top: 64px;
    padding-bottom: 64px;
}

#page5 > .bottom > .info-wrap > div {
    flex: 1;
}

#page5 > .bottom > .info-wrap > div:last-child {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

#page5 > .bottom > .info-wrap > div > p {
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
}

#page5 > .bottom > .info-wrap > div > a {
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-decoration: underline;
}
#page5 > .bottom > .info-wrap > div > a + a {
    margin-left: 20px;
}

@media screen and (max-width: 640px) {
    #page5 p {
        font-size: calc(4.5vw * 2);
        letter-spacing: calc(-0.03vw * 2);
        line-height: calc(6.9vw * 2);
    }

    #page5 > .body > .btn-wrap > a {
        width: 45vw;
    }

    #page5 > .body > .btn-wrap > a + a {
        margin-left: 2vw;
    }

    #page5 > .body > .btn-wrap > a > img {
        width: 100%;
    }

    #page5 > .bottom > .info-wrap {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        display: block;
    }
    
    #page5 > .bottom > .info-wrap > div:last-child {
        margin-top: 20px;
        display: block;
        text-align: center;
    }
    
    #page5 > .bottom > .info-wrap > div > p {
        color: #ffffff;
        font-size: 3.5vw;
        line-height: 5vw;
        font-weight: 400;
        text-align: center;
    }
    
    #page5 > .bottom > .info-wrap > div > a {
        color: #ffffff;
        font-size: 3.5vw;
        line-height: 5vw;
        font-weight: 400;
        text-decoration: underline;
    }
    #page5 > .bottom > .info-wrap > div > a + a {
        margin-left: 20px;
    }
}