#page4 {
    min-width: 100%;
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#page4 p {
    font-weight: 700;
    font-size: 64px;
    letter-spacing: -0.5px;
    color: black;
    line-height: 100px;
    text-align: center;
}

#page4 > .fire-wrap {
    width: 396px;
    height: 396px;
    border-radius: 50%;
    background-color: rgb(255, 236, 213);
    text-align: center;
    align-content: center;
    margin-bottom: 80px;
    position: relative;
}

#page4 > .fire-wrap > img {
    width: 80%;
}

#page4 > .fire-wrap > .balloon {
    position: absolute;
    background-color: rgba(245, 246, 246, 0.7);
    padding: 1.4vw 2.8vw;
    border-radius: 3.5vw;
    position: absolute;
    animation: ani3 1s infinite linear alternate;
}
#page4 > .fire-wrap > .balloon:last-child {
    animation: ani3 1s infinite linear alternate-reverse;
}

#page4 > .fire-wrap > .balloon > p {
    font-size: 36px;
    line-height: 22px;
    letter-spacing: -0.5px;
    color: #818BA0;
}

#page4 > .fire-wrap > .balloon#b1 {
    left: 0;
    top: -4.1vw;
}

#page4 > .fire-wrap > .balloon#b2 {
    right: 0;
    top: 0;
}

#page4 > .fire-wrap > .balloon#b3 {
    left: 1.4vw;
    bottom: -0.69vw;
}

@media screen and (max-width: 640px) {
    #page4 p {
        font-size: calc(4.5vw * 2);
        letter-spacing: calc(-0.03vw * 2);
        line-height: calc(6.9vw * 2);
    }
    
    #page4 > .fire-wrap {
        width: calc(26.9vw * 2.5);
        height: calc(26.9vw * 2.5);
        margin-bottom: calc(5.4vw * 2.5);
    }
    
    #page4 > .fire-wrap > .balloon {
        padding: calc(1.4vw * 2.5) calc(2.8vw * 2.5);
        border-radius: calc(3.5vw * 2.5);
    }

    #page4 > .fire-wrap > .balloon#b1 {
        top: calc(-4.1vw * 2.5);
    }

    #page4 > .fire-wrap > .balloon#b3 {
        left: 1.4vw;
        bottom: calc(-0.69vw * 2.5);
    }
    
    #page4 > .fire-wrap > .balloon > p {
        font-size: calc(2.5vw * 2.5);
        line-height: calc(1.5vw * 2.5);
        letter-spacing: calc(-0.03vw * 2.5);
    }
}

@keyframes ani3 {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(0.7vw);
    }
}