#page3 {
    min-width: 100%;
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

#page3 > .wrap {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 66vw;
    text-align: right;
}

#page3 > .wrap > img {
    width: 50vw;
    position: absolute;
    right: 0;
    bottom: 0;
}

#page3 > .wrap > p {
    position: absolute;
    bottom: 40vh;
}

#page3 p {
    font-weight: 700;
    font-size: 4vw;
    letter-spacing: -0.03vw;
    color: black;
    line-height: 6vw;
    text-align: left;
}

@media screen and (max-width: 1440px) {
    #page3 > .wrap {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 86vw;
        text-align: right;
    }
    
    #page3 > .wrap > img {
        width: 75vw;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    
    #page3 > .wrap > p {
        position: absolute;
        bottom: 28.75vw;
    }
    
    #page3 p {
        font-weight: 700;
        font-size: 4.5vw;
        letter-spacing: -0.03vw;
        color: black;
        line-height: 6.9vw;
        text-align: left;
    }
}

@media screen and (max-width: 640px) {
    #page3 > .wrap {
        width: 100vw;
    }

    #page3 > .wrap > img {
        width: 135vw;
        position: absolute;
    }

    #page3 > .wrap > p {
        bottom: 125vw;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    #page3 p {
        width: 100vw;
        font-size: calc(4.5vw * 2);
        letter-spacing: calc(-0.03vw * 2);
        line-height: calc(6.9vw * 2);
    }
}
